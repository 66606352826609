$primary: #ff8a37;
$bcg: white;
$bcgLight: #f0f2f5;
$font: #3e3e3e;
$border: #929292;
$green: #00b200;
$red: #c50000;
$boxShadow: 0 1px 6px rgba(0, 0, 0, 0.2);

.green    { color: $green }
.red   { color: $red }
.bcg_red   { background: $red }
.border   { color: $border }
.cms    {
  .light_block {
    background: $bcgLight;
    border: 1px solid $border;
    padding: 10px;
  }
  .dark_block    {
    background: $bcg;
    box-shadow: $boxShadow;
    padding: 15px;
  }
  input {
    background: lighten($bcg, 70);
  }
  .box    {
    box-shadow: $boxShadow;
    background: $bcg;
    padding: 10px;
  }
}
/*
 * CMS LOGIN
 */
.cms_login_form   {
  max-width: 460px;
  width: 100%;
  background: $bcg;
  padding: 20px;
  box-shadow: $boxShadow;
}
.cms_login_form img  {
  max-width: 260px;
}
/*
 *  OVERRIDE
 */
body.cms    {
  background: $bcgLight;
  color: $font;
}
.cms    {
  color: $font;
  .drawer   {
    background: $bcgLight;
  }
  .overlay  {
    background: $font;
  }
  .builder_modal_inner    {
    background: $bcgLight;
  }

  a:link, a:visited, .btn-link, .btn-link:focus {
    color: $primary;
    text-decoration: none;
  }
  a:hover, a.primary:link, a.primary:visited,
  .btn-link:hover, .btn-link:active, .btn-link.primary  {
    color: $primary;
    text-decoration: none;
  }
  input:focus {
    border: 1px solid $primary;
  }
  a.btn-primary, .btn-primary, .btn-primary:active    {
    color: white;
    background-color: $primary !important;
    border-color: $primary !important;
  }
  a.btn-primary:hover, .btn-primary:hover    {
    background-color: lighten($primary, 10) !important;
  }
  .btn-outline-primary, .btn-outline-primary:active    {
    color: $primary !important;
    background-color: transparent !important;
    border-color: $primary !important;
  }
  .btn-outline-primary:hover    {
    color: white;
    background-color: $primary;
  }
  .btn-link   {
    margin: 0;
    padding: 0;
  }
  input[type='checkbox'] {
    width: 22px;
    height: 22px;
    margin-top: -1px;
    filter: hue-rotate(180deg) brightness(1.7);
    vertical-align: middle;
  }
  .table {
    color:  $font;
    background: $bcg;
  }
  table td {
    vertical-align: middle !important;
  }
}

/*
    LEFT MENU
 */
.left_menu     {
  z-index: 5;
  width: 260px;
  left: 0;
  height: 100vh;
  position: fixed;
  transition: left 0.3s ease-out;
  overflow-y: auto;
  background: $bcg;
  box-shadow: $boxShadow;
}
.left_menu_buffer    {
  height: 100px;
  flex: 0 0 260px;
  transition: flex 0.3s ease-out;
}
.left_menu_closed   {
  left: -300px;
}
.left_menu_buffer_closed   {
  flex: 0 0 0;
}
.left_menu > ul li a   {
  text-decoration: none;
  display: inline-block;
  padding: 12px 16px;
  width: 100%;
  border-bottom: 1px solid $bcgLight;
}
.left_menu > ul li a:link, .left_menu > ul li a:visited   {
  color: $font;
}
.left_menu > ul li a:hover, .left_menu > ul li.active   {
  background-color: $primary;
  color: white;
}
.left_menu > ul li.active a  {
  color: white;
}
.left_menu ul ul {  // Poditem
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height .3s ease-in-out; /* Safari */
  transition: max-height .3s ease-in-out;
}
.left_menu_count {
  background: $red;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  line-height: 24px;
  text-align: center;
}
/*
    OVERRIDE NESTABLE
 */
.nestable_icon   {
  cursor: pointer;
  display:inline-block;
  width: 28px;
  font-size: 20px;
  height: 28px;
  line-height: 28px;
  background: $primary;
  text-align: center;
  margin-right: 8px;
  border-radius: 2px;
  color: white;
}
.nestable-item-name {
  background: $bcg;
  padding: 8px;
  box-shadow: $boxShadow;
}
.nestable_settings    {
  margin-top: 10px;
  padding: 10px;
  background: $bcgLight;
}
/*
 * STORAGE
 */
.bcg_image  {
  padding-top: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.background_cover {
  background-size: cover;
}
.storage_preload    {
  border: 1px solid $primary;
  border-radius: 4px;
  padding: 4px;
  position: relative;
  margin: 5px;
}
.storage_preload_loader   {
  position: absolute;
  left: 5px;
  right: 5px;
  top: 50%;
  margin-top: -15px;
  height: 30px;
  border: 1px solid $primary;
  background: white;
}
.storage_preload_loader div  {
  height: 100%;
  background: $primary;
}

.storage_file    {
  border: 2px solid $border;
  border-radius: 3px;
  padding: 3px;
  position: relative;
  margin: 3px;
  cursor: pointer;
}
.storage_file i    {
  position: absolute;
  top: 5px;
  right: 5px;
  color: $green;
  font-size: 26px;
  display: none;
}
.storage_file.selected    {
  border: 3px solid $green;
}
.storage_file.selected i   {
  display: block;
}
.storage_footer   {
  position: fixed;
  bottom: 10px;
  right: 10px;
  padding: 15px;
  background: $bcg;
  border: 1px solid $border;
}
/*
 * ALERTS
 */
.success_alert, .error_alert    {
  background: $green;
  color: white;
  padding: 10px;
  position: fixed;
  font-weight: bold;
  width: 120px;
  text-align: center;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 1000;
  top: -50px;
  transition: top .3s ease 0s;
}
.alert_open    {
  top: 20px;
}
.error_alert    {
  width: auto;
  max-width: 600px;
  background: $red;
}

/*******************************
******************************* BUILDER
 *******************************/

/*
 * SETTINGS TOP HEADER (globalna podesavanja, preview mode...)
 */
.builder_top_settings   {
  z-index: 10;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background: $bcg;
  border-bottom: 1px solid $border;
}
/*
 * SETTINGS DRAWER
 */
.builder_drawer    {
  z-index: 5;
  position: fixed;
  width: 360px;
  background-color: $bcg;
  height: 100%;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  border-left: 1px solid $border;
}
.builder_drawer_buffer    {
  flex: 0 0 360px;
}
.builder_drawer_header    { // Close btn and name
  padding: 5px;
  background: $bcgLight;
  margin-bottom: 10px;
}
.builder_slider   {
  background: $bcgLight;
}

.nestable_inline li    {
  display: inline-block;
  width: 200px;
  margin-right: 10px;
}