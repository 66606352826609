@import "draft.css";
@import '~@draft-js-plugins/image/lib/plugin.css';
@import '~@draft-js-plugins/alignment/lib/plugin.css';
@import '~@draft-js-plugins/focus/lib/plugin.css';
@import '~@draft-js-plugins/anchor/lib/plugin.css';
@import '~@draft-js-plugins/inline-toolbar/lib/plugin.css';
@import '~@draft-js-plugins/undo/lib/plugin.css';

.gray_block   {
  background: #f1f1fa;
  padding: 10px 15px;
}
.accent_bcg {
  background: #fff1f7;
}
/*
   BOTTOM NAV
 */
.bottom_nav   {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
}
.bottom_nav a:link, .bottom_nav a:visited   {
  color: white !important;
  display: inline-block;
  width: 100%;
  padding: 10px 0;
  font-weight: bold;
  font-size: 12px;
}
.text-gray-500 {
  color: gray;
}
.promo_codes_table td {
  padding: 2px 3px;
}
/*
  *  CATEGORIES DRAWER
 */
.categories   {
  white-space: nowrap;
  width: auto;
  overflow: hidden;
  height: 100%;
}
.left_panel   {
  vertical-align: top;
  flex: 0 0 340px;
  height: 100%;
  overflow-y: auto;
  white-space: normal;
}
.right_panel   {
  vertical-align: top;
  height: 100%;
  overflow-y: auto;
  flex-grow: 1;
  white-space: normal;
}
.categories a  {
  display: inline-block;
  width: 100%;
}
@media only screen and (max-width: 760px) {
  .left_panel, .right_panel   {
    display: inline-block;
    width: 100%;
    transition: margin-left .3s ease-in-out;
  }
  .left_panel_slide {
    margin-left: -100%;
  }
  .categories_back    {
    background: rgba(0, 0, 0, 0.3);
    transition: transform .2s ease-in-out;
    transform: scale(0);
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 10px;
    text-align: right;
    z-index: 10;
  }
  .left_panel_slide .categories_back    {
    transform: scale(1);
  }
}
.categories_btn_round    {
  font-size: 30px;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  line-height: 35px;
}
.drawer.show .basket_close_btn   {
  display: block !important;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 2;
}
@media only screen and (max-width: 760px) {
  .drawer.show .close_btn    {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 2;
  }
}
.pill {
  padding: 3px 6px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  color: white;
}

// HEADER SEARCH
.search_form    {   // SHOW FORM OVER OVERLAY
  position: relative;
  transition: all .3s ease-in-out;
  top: 0;
}
.search_form_show   {
  z-index: 11;
}
@media only screen and (max-width: 760px) {
  .search_form_show   {
    position: fixed;
    top: 16px;
    left: 15px;
    right: 15px;
  }
}
.prepend_form   {
  position: relative;
  font-size: 20px;
}
.prepend_form .close_btn  {
  margin: 0;
  padding: 0;
  right: -10px;
  top: -10px;
  font-size: 14px;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  text-align: center;
  line-height: 28px;
  color: white;
  position: absolute;
  display: none;
}
.search_form_show .close_btn  {
  display: block;
  z-index: 4;
}
.prepend_form .search_btn  {
  font-size: 20px;
  top: 50%;
  transform: translateY(-50%);
  left: 4px;
  position: absolute;
}
.prepend_form input  {
  height: auto;
  /*padding-left: 48px;*/
  background: white;
  color: gray;
  font-weight: 500;
  border-radius: 8px;
}
.prepend_form.autocomplete input   {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.search_autocomplete    {
  position: absolute;
  width: 100%;
  background: white;
  border-radius: 8px;
  z-index: 4;
  max-height: 75vh;
  overflow-y: auto;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.search_autocomplete_active    {
  background: #e0edff;
}
.search_autocomplete_image   {
  border-radius: 2px;
  overflow: hidden;
  min-height: 50px;
}
.search_autocomplete a   {
  padding: 6px 10px;
}

/*
  HEADER MENU
 */
.header_menu   {
  overflow-x: auto;
  border-bottom: 1px solid gray;
  background: white;
  padding-left: 15px;
}
.header_menu_link {
  margin-right: 20px;
  font-weight: bold;
}
@media only screen and (max-width: 760px) {
  .header_menu    {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 7;
    height: 40px;
    box-shadow:  0 1px 6px rgba(0, 0, 0, 0.2);;
  }
  .header_menu a   {

  }
  .header_menu_link {

  }
}
.header_menu_active    {
  margin-right: 20px;
  font-weight: bold;
  border-radius: 3px;
}

/*
  TITLE
 */
.builder_title    {
}
.builder_title > span    {
  display: inline-block;
  z-index: 2;
  line-height: 1;
  position: relative;
}
.builder_title > span:after    {
  position: absolute;
  bottom: -2px;
  display: inline-block;
  left: 0;
  width: 80px;
  content: "";
  z-index: -1;
}
.builder_title_sm > span:after  {
  width: 50%;
}

// INDEX SLIDER
.scroll_slider   {
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.scroll_slider::-webkit-scrollbar { /** WebKit */
  display: none;
}
.scroll_slider_wrapper   {
  white-space: nowrap;
  width: auto;
}
.scroll_slider_wrapper > div {
  vertical-align: top;
  display: inline-block;
}
.index_slider_selected .slide   {
  padding: 45px 25px;
}
/*
  ARROWS
 */
.arrows   {
  z-index: 2;
  position: absolute;
  top: 50%;
  margin-top: -25px;
  width: 100%;
  opacity: 0;
  -webkit-transition: opacity .5s ease-in-out; /* Safari */
  transition: opacity .3s ease-in-out;
}
.arrows_hoverable:hover .arrows {
  opacity: 1;
}
.arrow_left, .arrow_right {
  position: absolute;
  height: 50px;
  width: 50px;
  line-height: 38px;
  border-radius: 25px;
  font-size: 20px;
  left: 8px;
  text-align: center;
  color: white;
}
.arrow_right {
  left: auto;
  right: 8px;
}
.arrows_spread .arrow_left {
  left: -14px;
}
.arrows_spread .arrow_right {
  right: -14px;
}

.arrow_left:hover, .arrow_right:hover  {
  background: red;
  color: white;
}
@media only screen and (max-width: 760px) {
  .arrow_left, .arrow_right {
    height: 40px;
    width: 40px;
    line-height: 30px;
    font-size: 16px;
  }
  .arrows   {
    opacity: 1;
    margin-top: -20px;
  }
}


/*
 * CAROUSEL
 */
.carousel_slider   {
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.carousel_slider::-webkit-scrollbar { /** WebKit */
  display: none;
}
.carousel_slider_wrapper   {
  white-space: nowrap;
  width: auto;
}
.carousel_slider_wrapper > div   {

}
.carousel_product   {
  vertical-align: top;
  display: inline-block;
  width: 20%;
  line-break: normal;
  white-space: pre-wrap;
}
@media only screen and (max-width: 760px) {
  .carousel_product   {
    width: 50%;
  }
}


/*
 *  SHOP
 */
.product_gallery_tmb    {
  width: 100px;
}
@media only screen and (max-width: 760px) {

}
.bordered   {
  border: 1px solid lightgrey;
  border-radius: 4px;
}
/*
 * PRODUCT
 */
.product_divider    {
  position: absolute;
  top: 50%;
  height: 140px;
  margin-top: -70px;
  right: -1px;
  border-left: 1px solid transparent;
  border-right: 1px solid lightgray;
}
.shop_product:last-child .product_divider    {
  border-right: 1px solid transparent;
  right: 0;
}
.shop_product:nth-child(4n+4) .product_divider    {
  border-right: 1px solid transparent;
  right: 0;
}
@media only screen and (max-width: 768px) {
  .shop_product:nth-child(2n+2) .product_divider    {
    border-right: 1px solid transparent;
    right: 0;
  }
}
.product_tags   {
  position: absolute;
  top: 8px;
  right: 0;
}
.product_tags div  {
  margin-bottom: 2px;
  border-radius: 4px;
  font-weight: bold;
  color: white;
  font-size: 12px;
  text-align: center;
  padding: 3px 8px;
}

/*
  FLOATING SIDEBAR
 */
$floatingWidth: 40px;
.floating_sidebar   {
  z-index: 3;
  position: fixed;
  right: 20px;
  bottom: 70px;
  width: $floatingWidth;
  font-size: 16px;
}
@media only screen and (max-width: 760px) {
  .floating_sidebar   { // TO TOP na telefonu
    right: auto;
    left: 20px;
    bottom: 15px;
  }
}
.floating_item      {
  margin-bottom: 8px;
  position: relative;
  text-align: center;
}
.floating_item:hover .link     {
  color: white;
}
.floating_item .icon     {
  width: $floatingWidth;
  color: white;
  height: $floatingWidth;
  line-height: $floatingWidth;
}
.floating_item:hover .icon     {
  //background: $primary;
  //border: 1px solid $primary;
}
.floating_item .slide     {
  position: absolute;
  line-height: $floatingWidth;
  width: 90px;
  height: $floatingWidth;
  margin-left: -90px;
  top: 0;
}
.floating_item:hover .slide     {
  display: block !important;
}
.floating_help  {
  height: 220px;
  margin-bottom: 8px;
  overflow: hidden;
}
.floating_help .slided  {
  font-size: 14px;
  width: 220px;
  margin-right: -220px;
  opacity: 0;
  -webkit-transition: margin-right 0.3s, opacity .4s ease-in; /* Safari prior 6.1 */
  transition: margin-right 0.3s, opacity .4s ease-in;
}
.floating_help .slided_open  {
  opacity: 1;
  margin-right: -0;
}
.floating_help .help_toggle    {
  display: inline-block;
  height: 100%;
  width: $floatingWidth;
  //ackground: $bcg2;
  //color: $primary;
  font-weight: 500;
}
.floating_help .help_toggle:hover {
  color: white;
  //background: $primary;
}
.floating_help .help_toggle_open    {
  //background: $primary !important;
  color: white;
}
.floating_help .vertical {
  transform-origin: 10px 24px;
  transform: rotate(90deg);
  white-space: nowrap;
}

.footer_newsletter a   {
  width: 46px;
  height: 46px;
  line-height: 34px;
  text-align: center;
  border-radius: 50%;
  margin-right: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2) !important;
}
.footer_newsletter .input-group {

}
.footer_newsletter input   {
  height: 44px;
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2) !important;
}
.footer_newsletter button   {
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2) !important;
}

.user_bcg {
  background: #f3f3f3
}
.breadcrumbs i {
  margin-left: 15px !important;
}

.instagram_bcg  {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.option_mb:last-child    {
  margin-bottom: 0 !important;
}
.form-control {
  padding-top: 0;
  padding-bottom: 0;
}
.select_checkbox input + span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.select_checkbox input:checked + span {
  border: 3px solid;
}
a.btn-primary {
  color: white !important;
}

.order_banks a   {
  max-width: 100px;
  margin: 4px 6px;
}