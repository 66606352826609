.RichEditor-root {
  border: 1px solid #ddd;

  font-size: 14px;
  padding: 15px;
}
.RichEditor-root h1,.RichEditor-root h2,.RichEditor-root h3,.RichEditor-root h4,.RichEditor-root h5,.RichEditor-root h6 ,
.DraftEditor-root h1,.DraftEditor-root h2,.DraftEditor-root h3,.DraftEditor-root h4,.DraftEditor-root h5,.DraftEditor-root h6  {
  font-size: revert;
}
.RichEditor-root h1, .DraftEditor-root h1 {
  @apply text-3xl;
}
.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}
.RichEditor-editor img {
  max-width: 100%;
}
.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-blockquote {
  border-left: 5px solid #eee;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}


.DraftEditor-root img {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
}
.public-DraftEditor-content:not(.notranslate) img {
  box-shadow: none !important;
}
@media only screen and (max-width: 764px) {
  .DraftEditor-root img {
    float: none !important;
    width: auto !important;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}